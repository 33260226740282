import { registerLocaleData } from "@angular/common";
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import localeCs from "@angular/common/locales/cs";
import localeDe from "@angular/common/locales/de";
import localeEs from "@angular/common/locales/es";
import localeFr from "@angular/common/locales/fr";
import localeIt from "@angular/common/locales/it";
import localeNl from "@angular/common/locales/nl";
import localePt from "@angular/common/locales/pt";
import { ErrorHandler, NgModule } from "@angular/core";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { CloudinaryModule } from "@cloudinary/ng";
import {
  TranslateLoader,
  TranslateModule,
  TranslateParser,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { GoogleTagManagerModule } from "angular-google-tag-manager";
import { ToastrModule } from "ngx-toastr";
import { environment } from "../environments/environment";

import { versions } from "../environments/version";
import { AuthService } from "../harmony/angular";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrandModule } from "./brand/brand.module";
import { CustomErrorHandler } from "./error.handler";
import { LayoutModule } from "./layout/layout.module";
import { PartnerModule } from "./partner/partner.module";
import { ScreenshotModule } from "./screenshot/screenshot.module";
import { MyMatPaginatorIntl } from "./shared/material/mat-paginator-intl";
import { AngularMaterialModule } from "./shared/material/material.module";
import { SharedPipesModule } from "./shared/pipes/shared-pipes.module";
import { AuthTokenService } from "./shared/services/api/auth-token.service";
import { AuthNetworkService } from "./shared/services/api/auth.network.service";
import { SharedServicesModule } from "./shared/services/shared-services.module";
import { TrackingService } from "./shared/services/tracking.service";
import { NoCookiesModule } from "./static/no-cookies/no-cookies.module";
import { StaticModule } from "./static/static.module";
import { AuthModule } from "./auth/auth.module";
import {
  DayjsDateAdapter,
  MAT_DAYJS_DATE_ADAPTER_OPTIONS,
  MAT_DAYJS_DATE_FORMATS,
} from "./shared/utils/dayjs-date-adapter";
import { SharedComponentsModule } from "./shared/components/shared-components.module";
import { UnsupportedBrowserModule } from "./static/unsupported-browser/unsupported-browser.module";
import { DeviceConsoleLogger, Logger } from "../harmony/core";
import { SentryService } from "./shared/services/sentry.service";
import { NotFoundService } from "./shared/services/not-found.service";
import { getInitialLocale } from "./shared/services/language.service";

export const TranslateLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(
    http,
    "./assets/locale/",
    ".json?v=" + versions.version,
  );

registerLocaleData(localeCs, "cs");
registerLocaleData(localeDe, "de");
registerLocaleData(localeEs, "es");
registerLocaleData(localeFr, "fr");
registerLocaleData(localeIt, "it");
registerLocaleData(localeNl, "nl");
registerLocaleData(localePt, "pt");

export const MatPaginatorIntlFactory = (
  translateService: TranslateService,
  translateParser: TranslateParser,
): MyMatPaginatorIntl =>
  new MyMatPaginatorIntl(translateService, translateParser);

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    SharedComponentsModule,
    SharedPipesModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AuthModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
      iconClasses: {
        error: "toast-error",
        info: "toast-info",
        success: "toast-success",
        warning: "toast-warning",
      },
    }),
    CloudinaryModule,
    GoogleTagManagerModule.forRoot({
      id: environment.google.tagManagerId,
    }),
    LayoutModule,
    NoCookiesModule,
    AngularMaterialModule,
    SharedServicesModule,
    ScreenshotModule,
    PartnerModule,
    BrandModule,
    StaticModule,
    RouterModule.forRoot([], {
      scrollPositionRestoration: "enabled",
    }),
    UnsupportedBrowserModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: Logger, useFactory: () => new DeviceConsoleLogger() },
    {
      provide: MAT_DATE_LOCALE,
      useValue: getInitialLocale(),
    },
    {
      provide: DateAdapter,
      useClass: DayjsDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: "MONOLITH_API_URL",
      useValue: `${environment.api.baseUrl}${environment.api.apiPathV1}`,
    },
    {
      provide: AuthNetworkService,
      deps: [AuthTokenService],
      useFactory: (authTokenService: AuthTokenService): AuthService =>
        new AuthNetworkService(authTokenService),
    },
    {
      provide: "MONOLITH_HARMONY_API_URL",
      useValue: `${environment.api.baseUrl}${environment.api.apiPathV2}`,
    },
    {
      provide: "API_GATEWAY_URL",
      useValue: environment.api.baseGatewayUrl,
    },
    {
      provide: Logger,
      useFactory: () => new DeviceConsoleLogger(),
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DAYJS_DATE_FORMATS },
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService, TranslateParser],
      useFactory: MatPaginatorIntlFactory,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "outline" },
    },
    TrackingService,
    NotFoundService,
    SentryService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
